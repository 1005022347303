.loader {
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: 100;
    background-color: var(--bg-pri);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    transition: opacity 1s linear 1s;
}

.loader p {
    font-family: cursive;
    letter-spacing: 1.5px;
    font-size: 2.5rem;
    font-weight: 600;
    transition: opacity 1s linear;
}

.loading {
    padding: 0.25rem;
    border-radius: 50%;
    background-color: currentColor;
    animation: blink 2s linear infinite;
    transition: opacity 1s linear;
}

@keyframes blink {
    50% {
        filter: opacity(0);
    }
}

.loader.done,
.loader.done p,
.loader.done .loading {
    opacity: 0;
}

.banner {
    width: 100%;
    height: calc(100svh + 1px);
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease;
    --scrollY: 0px;
}

#theme[data-mode=light] .banner {
    --bg-sun-moon: #fff;
    --bg-opacity-light: 1;
    --bg-opacity-dark: 0;
}

#theme[data-mode=dark] .banner {
    --bg-sun-moon: #feff80;
    --bg-opacity-light: 0;
    --bg-opacity-dark: 1;
}

.banner img {
    position: absolute;
    bottom: 0;
    width: 100%;
    transition: opacity 0.5s ease;
}

.banner img.light {
    opacity: var(--bg-opacity-light);
}

.banner img.dark {
    opacity: var(--bg-opacity-dark);
}

.bg-light,
.bg-dark {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: opacity 0.5s ease;
}

.bg-light {
    background-color: #99d9ea
}

.bg-dark {
    background-color: #514c9c;
    opacity: var(--bg-opacity-dark);
}

.layer-3 {
    position: absolute;
    top: 6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: color 0.5s ease;
}

.layer-3 p {
    width: min(80vw, 35rem);
    text-align: center;
    text-wrap: balance;
    opacity: 0.75;
    font-size: 0.875rem;
    margin: 1.25rem 0;
    letter-spacing: 1px;
    line-height: 1.25rem;
}

.layer-3 .scroll-gif {
    width: 1.2rem;
    height: 2.2rem;
    border-radius: 30px;
    border: 2px solid currentColor;
    opacity: 0.7;
    position: relative;
    transition: border-color 0.5s ease;
}

.layer-3 .scroll-gif::after {
    content: '';
    height: 0.35rem;
    border-left: 1px solid;
    border-right: 1px solid;
    opacity: 0.7;
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translateX(-50%);
    animation: mouse 2s linear infinite;
}

@keyframes mouse {
    50% {
        top: -30%;
    }
}

.layer-1 {
    transform: translateY(calc(min(var(--scrollY), 100svh) / 1.5));
    pointer-events: none;
}

.layer-2 {
    transform: translateY(calc(min(var(--scrollY), 100svh) / 2));
    pointer-events: none;
}

.layer-3 {
    transform: translateY(calc(min(var(--scrollY), 100svh) / 1.5));
}

.layer-4 {
    transform: translateY(calc(min(var(--scrollY), 100svh) / 2));
    pointer-events: none;
}

.layer-5 {
    transform: translateY(calc(min(var(--scrollY), 100svh) / 3));
    pointer-events: none;
}

.layer-6 {
    pointer-events: none;
}

.sun-moon {
    position: absolute;
    border-radius: 50%;
    height: 14.75vw;
    width: 14.75vw;
    bottom: calc(100svh - max(1.75rem, 4vw));
    left: 50%;
    transform: translate(-49%, calc(min(var(--scrollY), 100svh) / 1.25));
    cursor: pointer;
    background-color: var(--bg-sun-moon);
    transition: background-color 0.5s ease;
}

.sun-moon.change {
    animation: sunset 2s ease-in-out;
}

@keyframes sunset {
    50% {
        bottom: 10.7vw;
    }
}

.popup {
    position: absolute;
    top: 0.25rem;
    right: calc(50% + 7.375vw + 1rem);
    width: 9rem;
    border-radius: 15px;
    border-top-right-radius: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 0.5rem 1rem;
    color: rgba(255, 255, 255, 0.8);
    font-size: 0.9rem;
}

.popup::after {
    content: '';
    position: absolute;
    top: 0;
    border-top: 1rem solid rgba(0, 0, 0, 0.5);
    border-right: 1rem solid transparent;
    right: calc(-1rem + 1px);
}