.star {
    width: 100%;
    position: absolute;
    inset: 0;
    opacity: 0;
}

.star.fade {
    animation: fadeStars 2.5s ease forwards;
}

@keyframes fadeStars {
    100% {
        opacity: 1;
    }
}