.projects-container {
    padding: 3rem 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: background-image 0.5s ease;
    position: relative;
}

#theme[data-mode=light] .projects-container {
    --bg-card: #edd3c0;
    --txt-blur: 15px;
    --cover-bg: #a96739;
}

#theme[data-mode=dark] .projects-container {
    --bg-card: #22225D;
    --txt-blur: 30px;
    --cover-bg: #1d1d4f;
}

.projects-container::after {
    content: '';
    position: absolute;
    top: -1px;
    height: 2px;
    width: 100%;
    background-color: var(--cover-bg);
}

.projects-container h1 {
    text-align: center;
    margin: 2.5rem 0;
}

.projects-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 2rem;
    overflow-y: hidden;
}

.projects-wrapper.less {
    height: 44rem;
}

.project-card {
    width: min(21.5rem, 100%);
    height: 21rem;
    border-radius: 10px;
    background-color: var(--bg-card);
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.image-div {
    position: relative;
}

.project-img {
    width: 100%;
    border-radius: 15px;
    transition: filter 0.25s linear;
}

.image-div:hover .project-img {
    filter: blur(2.5px);
}

.logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    cursor: pointer;
    border-radius: 10px;
    background-color: var(--bg-card);
    font-size: 2rem;
    color: currentColor;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.25rem;
    height: 3.25rem;
    transition: opacity 0.25s linear, background-color 0.25s linear, transform 0.25s linear;
}

.logo:hover {
    background-color: color-mix(in lab, var(--bg-card), currentColor 15%);
}

.logo:active {
    transform: translate(-50%, -50%) scale(0.95);
}

.image-div:hover .logo {
    opacity: 1;
}

.github {
    position: absolute;
    top: 5%;
    right: 5%;
    border-radius: 50%;
    background-image: linear-gradient(to right, #434343, #000000);
    color: white;
    font-size: 1.5rem;
    padding: 0.25rem;
    display: flex;
    cursor: pointer;
    transition: transform 0.25s linear;
}

.github:hover {
    transform: scale(1.1);
}

.github:active {
    transform: scale(0.95);
}

.project-card h2:has(svg) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.project-card h2 svg {
    color: crimson;
}

.project-card p {
    --difference: 25%;
    font-size: 0.9rem;
    color: var(--txt-sec);
}

.tags {
    display: flex;
    gap: 0.5rem;
}

.tags span {
    color: color-mix(in lab, var(--tag-color), currentColor 20%);
    font-size: 0.8rem;
    font-weight: 500;
    text-shadow: 0 0 var(--txt-blur) var(--bg-pri);
}

.more-btn {
    margin-top: 2rem;
    width: min(21.5rem, 100%);
    padding: 0.5rem 0;
    border-radius: 20px;
    font-weight: 500;
    color: inherit;
    background-color: var(--bg-card);
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: background-color 0.25s linear, transform 0.25s linear;
}

.more-btn:hover {
    background-color: color-mix(in lab, var(--bg-card), currentColor 15%);
}

.more-btn:active {
    transform: scale(0.95);
}

@media (pointer: coarse) {

    .image-div:hover .project-img {
        filter: blur(0);
    }

    .logo {
        top: 5%;
        left: 5%;
        transform: translate(0, 0);
        opacity: 1;
        border-radius: 50%;
        width: 2.15rem;
        height: 2.15rem;
        font-size: 1.4rem;
    }

    #theme[data-mode=light] .logo.pulse {
        --pulse-color: #a98266;
    }

    #theme[data-mode=dark] .logo.pulse {
        --pulse-color: #8a82fb;
    }

    .logo.pulse::before,
    .logo.pulse::after {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        border-radius: 50%;
        background-color: var(--pulse-color);
        z-index: -1;
        opacity: 0.7;
    }

    .logo.pulse::before {
        animation: pulse 2s ease-out 5 forwards;
    }

    .logo.pulse::after {
        animation: pulse 2s ease-out 1s 5 forwards;
    }

    @keyframes pulse {
        100% {
            transform: scale(2.5);
            opacity: 0;
        }
    }

    .logo:hover,
    .more-btn:hover {
        background-color: var(--bg-card);
    }

    .logo:active,
    .more-btn:active {
        background-color: color-mix(in lab, var(--bg-card), currentColor 15%);
        transform: translate(0, 0) scale(0.95);
    }

    .github:hover {
        transform: scale(1);
    }

    .github:active {
        transform: scale(0.95);
    }
}