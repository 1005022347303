@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  color-scheme: light dark;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Poppins, sans-serif;
  -webkit-tap-highlight-color: transparent;
}

body {
  overflow-x: hidden;
}

body.no-scroll {
  overflow-y: hidden;
}

:root {
  font-size: clamp(14px, 11px + 0.3333vw, 16px);
  --difference: 20%;
  --txt-sec: color-mix(in lab, currentColor, var(--bg-pri) var(--difference))
}

:root:has(#theme[data-mode=light]) {
  color-scheme: light;
  --bg-pri: #fff;
  --bg-sec: #cfcfcf;
  --bg-inv: #000
}

:root:has(#theme[data-mode=dark]) {
  color-scheme: dark;
  --bg-pri: #000;
  --bg-sec: #252525;
  --bg-inv: #fff
}

:root:has(#theme[data-mode=light]) ::selection {
  color: #fff;
  background-color: #000;
}

:root:has(#theme[data-mode=dark]) ::selection {
  color: #000;
  background-color: #fff;
}